import useRegistrationForm from "@/hooks/useRegistrationForm";
import { useUserSession } from "@/hooks/useUserSession";

import { Button, Box, Progress, Checkbox, useToast } from "@chakra-ui/react";
import { increment, updateDoc } from "firebase/firestore";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type FormData = {
  cgv: boolean;
  newsletter: boolean;
};

const Terms = () => {
  const { t } = useTranslation();
  const { user } = useUserSession();
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<FormData>();
  const navigate = useNavigate();
  const toast = useToast();

  const onSubmit = handleSubmit(async (data) => {
    try {
      if (!user) return;
      await updateDoc(user.ref, {
        newsletter: data.newsletter,
        registrationCompleted: true,
        paperCoins: increment(60),
      });
      useRegistrationForm.persist.clearStorage();

      toast({
        title: t("auth.register.accountCompleted"),
        description: t("auth.register.accountCompletedDescription"),
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
      navigate("/");
    } catch (error) {
      setError("root", {
        type: "manual",
        message: t("auth.register.error"),
      });
    }
  });

  return (
    <Box
      as="form"
      display={"flex"}
      flexDir={"column"}
      gap={8}
      w={"clamp(280px, 70%, 500px)"}
      onSubmit={onSubmit}
    >
      <Progress value={100} />
      {errors.root && <Box color={"red"}>{errors.root.message}</Box>}

      <Checkbox colorScheme="brand" {...register("newsletter")}>
        {t("auth.register.newsletter")}
      </Checkbox>
      <Button type="submit" variant={"primary"} isLoading={isSubmitting}>
        {t("auth.register.finalizeAccount")}
      </Button>
    </Box>
  );
};

export default Terms;
