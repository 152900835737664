import { authIa, dbIa } from "@/lib/firebase";
import {
  Button,
  Input,
  Link as ChakraLink,
  Text,
  Box,
  Stack,
  useToast,
  Checkbox,
} from "@chakra-ui/react";
import { FirebaseError } from "firebase/app";
import { AuthErrorCodes, createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

type FormData = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  cgv: boolean;
};

const Credentials = () => {
  const { t } = useTranslation();
  const toast = useToast();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<FormData>();
  const navigate = useNavigate();
  const onSubmit = handleSubmit(async (data) => {
    try {
      const { user } = await createUserWithEmailAndPassword(
        authIa,
        data.email,
        data.password
      );
      await setDoc(doc(dbIa, "users", user.email!), {
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        createdAt: new Date(),
        registrationCompleted: false,
        cgv: data.cgv,
      });
      toast({
        title: t("auth.register.accountCreated"),
        description: t("auth.register.accountCreatedDescription"),
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
      navigate("/");
    } catch (error) {
      console.error(error);
      if (error instanceof FirebaseError) {
        switch (error.code) {
          case AuthErrorCodes.EMAIL_EXISTS:
            setError("email", {
              type: "manual",
              message: t("auth.register.userExists"),
            });
            break;
          default:
            setError("root", {
              type: "manual",
              message: t("auth.register.error"),
            });
            break;
        }
      } else {
        setError("root", {
          type: "manual",
          message: t("auth.register.error"),
        });
      }
    }
  });

  return (
    <Box
      as="form"
      display={"flex"}
      flexDir={"column"}
      gap={8}
      w={"clamp(280px, 70%, 500px)"}
      onSubmit={onSubmit}
    >
      {errors.root && <Box color={"red"}>{errors.root.message}</Box>}
      <Input
        type="email"
        placeholder={t("auth.login.email")}
        variant={"auth"}
        {...register("email")}
        required
      />
      {errors.email && <Box color={"red"}>{errors.email.message}</Box>}
      <Input
        type="password"
        placeholder={t("auth.login.password")}
        variant={"auth"}
        {...register("password")}
        required
      />
      {errors.password && <Box color={"red"}>{errors.password.message}</Box>}
      <Input
        type="text"
        placeholder={t("auth.login.firstName")}
        {...register("firstName")}
        required
        variant={"auth"}
      />
      {errors.firstName && <Box color={"red"}>{errors.firstName.message}</Box>}
      <Input
        type="text"
        placeholder={t("auth.login.lastName")}
        {...register("lastName")}
        required
        variant={"auth"}
      />
      {errors.lastName && <Box color={"red"}>{errors.lastName.message}</Box>}
      <Checkbox colorScheme="brand" required {...register("cgv")}>
        <Trans t={t} key={"auth.register.acceptCgv"}>
          J'ai lu et j'accepte les{" "}
          <ChakraLink
            textDecoration={"underline"}
            target="_blank"
            href="https://paperdoc-app.com/fr/privacy-policy"
          >
            conditions générales d'utilisation
          </ChakraLink>
        </Trans>
      </Checkbox>
      <Button type="submit" variant={"primary"} isLoading={isSubmitting}>
        {t("auth.register.createAccount")}
      </Button>
      <Stack
        direction={"column"}
        spacing={0}
        alignSelf={"center"}
        textAlign={"center"}
      >
        <Text>{t("auth.register.alreadyHaveAccount")} </Text>
        <ChakraLink textDecoration={"underline"} as={Link} to={"/login"}>
          {t("auth.register.signIn")}
        </ChakraLink>
      </Stack>
    </Box>
  );
};

export default Credentials;
