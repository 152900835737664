import { useUserSession } from "@/hooks/useUserSession";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  LinkBox,
  LinkOverlay,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const CompleteAuthBanner = () => {
  const { user } = useUserSession();
  const { t } = useTranslation();
  if (user?.registrationCompleted) {
    return null;
  }
  return (
    <LinkBox>
      <Alert status="info">
        <AlertIcon />
        <AlertTitle>
          <LinkOverlay as={Link} to="/complete-profile/status">
            {t("completeProfileBanner")}
          </LinkOverlay>
        </AlertTitle>
      </Alert>
    </LinkBox>
  );
};

export default CompleteAuthBanner;
