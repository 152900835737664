import useSummaryGeneration from "@/lib/hooks/useSummaryGeneration";
import ProgressBar from "@/ui/ia/ProgressBar";
import {
  Box,
  Button,
  CircularProgress,
  Heading,
  Link,
  ListItem,
  OrderedList,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { MdUploadFile } from "react-icons/md";

const CreateKnownSummary = (props: {
  corpusId: string;
  loadSummaries: () => Promise<void>;
  link: string;
}) => {
  const { corpusId, loadSummaries, link } = props;

  const [file, setFile] = useState<File | null>(null);
  const { t } = useTranslation();
  const { currentStep, loading, error, startKnownSummary } =
    useSummaryGeneration(async () => {
      await new Promise((resolve) => {
        setTimeout(resolve, 1500);
      });
      await loadSummaries();
    });

  return (
    <Box
      display={"flex"}
      flexDir={"column"}
      alignItems={"center"}
      gap={4}
      w={"100%"}
    >
      {error && (
        <Box
          mt={4}
          color="white"
          fontSize="1rem"
          textAlign="center"
          bgColor={"tomato"}
          p={4}
          borderRadius={12}
        >
          {error}
        </Box>
      )}
      <Box alignSelf={"stretch"}>
        <ProgressBar textColor="#000" step={currentStep} />
      </Box>

      {currentStep === 0 && (
        <>
          <Heading fontSize={24} as={"h3"}>
            {t("summarize.known.title")}
          </Heading>
          <OrderedList>
            <ListItem>
              <Trans t={t} i18nKey={"summarize.known.step1"}>
                Téléchargez le fichier en vous rendant sur la page de
                <Link
                  target="_blank"
                  color={"brand.500"}
                  textDecoration={"underline"}
                  href={link}
                >
                  l'article complet
                </Link>
              </Trans>
            </ListItem>
            <ListItem>{t("summarize.known.step2")}</ListItem>
            <ListItem>{t("summarize.known.step3")}</ListItem>
          </OrderedList>
          {loading ? (
            <CircularProgress isIndeterminate color="brand.500" />
          ) : (
            <>
              <Button
                as={"label"}
                colorScheme="brand"
                isDisabled={loading}
                rightIcon={<MdUploadFile />}
              >
                {t("summarize.known.upload")}
                <input
                  type="file"
                  accept=".pdf"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    if (e.target.files) {
                      setFile(e.target.files[0]);
                      startKnownSummary(`${corpusId}`, e.target.files[0]);
                    }
                  }}
                />
              </Button>
              {file && (
                <Text
                  textAlign={"center"}
                  sx={{
                    textWrap: "balance",
                  }}
                >
                  {t("summarize.uploadedFile")} : <strong>{file.name}</strong>
                </Text>
              )}
              <Button
                colorScheme="brand"
                isDisabled={loading || !file}
                onClick={() => {
                  startKnownSummary(`${corpusId}`, file!);
                }}
              >
                {t("summarize.open")}
              </Button>
            </>
          )}
        </>
      )}
      {currentStep === 1 && (
        <>
          <Heading as={"h2"}>{t("summarize.steps.processing")}</Heading>

          <CircularProgress isIndeterminate color="brand.500" />
          <Text fontSize={"1.5rem"}>{t("summarize.processing")}</Text>
          <Text fontSize={"1.5rem"}>{t("summarize.waitTime")}</Text>
        </>
      )}
      {currentStep === 2 && (
        <>
          <Heading as={"h2"}>{t("summarize.steps.formatting")}</Heading>
          <CircularProgress isIndeterminate color="brand.500" />

          <Text fontSize={"1.5rem"}>{t("summarize.formatting")}</Text>
          <Text fontSize={"1.5rem"}>{t("summarize.waitTime")}</Text>
        </>
      )}
      {currentStep === 3 && (
        <>
          <Heading as={"h2"}>{t("summarize.steps.done")}</Heading>
          <Text fontSize={"1.5rem"}>{t("summarize.done")}</Text>
          <Button
            colorScheme="brand"
            onClick={() => {
              loadSummaries();
            }}
          >
            {t("summarize.refresh")}
          </Button>
        </>
      )}
    </Box>
  );
};

export default CreateKnownSummary;
