import {
  Button,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UnorderedList,
  useDisclosure,
} from "@chakra-ui/react";
import { createContext, useCallback, useContext, useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type TDisclaimerType = "search" | "summary" | "archie";
type TPaperCoinsDisclaimerContext = {
  openDisclaimer: (type: TDisclaimerType, amount?: number) => void;
};

const PaperCoinsDisclaimerContext = createContext<TPaperCoinsDisclaimerContext>(
  {
    openDisclaimer: () => {},
  }
);

const PaperCoinsDisclaimer = ({ children }: { children: React.ReactNode }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [type, setType] = useState<TDisclaimerType | null>(null);
  const [amount, setAmount] = useState<number>(0);

  const openDisclaimer = useCallback(
    (type: TDisclaimerType, amount?: number) => {
      setType(type);
      amount && setAmount(amount);
      onOpen();
    },
    [onOpen]
  );

  const openPricing = () => {
    navigate("/pricing");
    onClose();
  };
  return (
    <PaperCoinsDisclaimerContext.Provider value={{ openDisclaimer }}>
      {children}
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t("papercoins.title")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody display={"flex"} flexDirection={"column"} gap={2}>
            <Text>
              {type === "search" && t("papercoins.disclaimer")}
              {type === "summary" && t("papercoins.disclaimerSummary")}
              {type === "archie" && t("papercoins.disclaimerArchie")}
            </Text>
            {type === "search" && (
              <>
                <Text>{t("papercoins.archie")}</Text>
                <Text>{t("papercoins.archieReminder")}</Text>
                <UnorderedList>
                  <ListItem>{t("papercoins.ia")}</ListItem>
                  <ListItem>{t("papercoins.insights")}</ListItem>
                  <ListItem>{t("papercoins.summ")}</ListItem>
                </UnorderedList>
              </>
            )}
            {type === "archie" && (
              <>
                <Text>{t("papercoins.archieAction", { count: amount })}</Text>
                <Text>{t("papercoins.archieReminder")}</Text>
                <UnorderedList>
                  <ListItem>{t("papercoins.insights")}</ListItem>
                  <ListItem>{t("papercoins.summ")}</ListItem>
                </UnorderedList>
              </>
            )}
            {type === "summary" && <Text>{t("papercoins.summary")}</Text>}
            <Text>{t("papercoins.how")}</Text>
          </ModalBody>
          <ModalFooter gap={4}>
            <Button onClick={onClose}>{t("papercoins.close")}</Button>
            <Button colorScheme="brand" onClick={openPricing}>
              {t("papercoins.pricing")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </PaperCoinsDisclaimerContext.Provider>
  );
};

const usePaperCoinsDisclaimer = () => useContext(PaperCoinsDisclaimerContext);

export { PaperCoinsDisclaimer, usePaperCoinsDisclaimer };
