import CollectionMenu from "@/components/collections/CollectionMenu";
import { useCollections } from "@/hooks/useCollections";
import Container from "@/ui/Container";
import {
  Box,
  Card,
  CardBody,
  CardFooter,
  Heading,
  HStack,
  Icon,
  IconButton,
  LinkBox,
  LinkOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";

import { useTranslation } from "react-i18next";
import { MdAdd } from "react-icons/md";
import { Link } from "react-router-dom";

const Collections = () => {
  const { t } = useTranslation();

  const { collections, openCreateCollection } = useCollections();

  return (
    <Container hasSearch>
      <Box
        bgImage={"/backgrounds/logo-collections.svg"}
        bgSize={"cover"}
        color={"#FFF"}
        mt={8}
        p={[4, 8]}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        gap={4}
        flex={1}
        borderRadius={24}
        bgColor={"#F2F6F9"}
      >
        <HStack alignItems={"center"} gap={4}>
          <Heading textAlign={"center"}>{t("collections.title")}</Heading>
          <IconButton
            bgColor="gray.50"
            aria-label={t("collections.create")}
            icon={<Icon as={MdAdd} w={8} h={8} color={"#17A4FF"} />}
            onClick={openCreateCollection}
          />
        </HStack>

        <VStack mt={4} alignItems={"stretch"} minW={"50%"} gap={4}>
          {collections.map((collection) => (
            <LinkBox
              minW={300}
              key={collection.id}
              bgColor={"white"}
              borderRadius={12}
              p={4}
              orientation="horizontal"
              as={Card}
            >
              <CardBody padding={0}>
                <HStack justifyContent={"space-between"}>
                  <LinkOverlay as={Link} to={`/collections/${collection.id}`}>
                    <Text fontWeight={"bold"}>
                      {collection.id === "default"
                        ? t("collections.favs")
                        : collection.name}
                    </Text>
                  </LinkOverlay>
                  {collection.id !== "default" && (
                    <CollectionMenu collection={collection} />
                  )}
                </HStack>
              </CardBody>
              <CardFooter padding={0}>
                <Text fontSize={"sm"} fontStyle={"italic"}>
                  {t("collections.count", {
                    papers: collection.papers.length,
                    searches: collection.searches.length,
                  })}
                </Text>
              </CardFooter>
            </LinkBox>
          ))}
        </VStack>
      </Box>
    </Container>
  );
};

export default Collections;
