import Container from "@/ui/Container";
import {
  Box,
  Button,
  HStack,
  Heading,
  Icon,
  Stack,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { ArrowBackIcon, LinkIcon } from "@chakra-ui/icons";

import { useTranslation } from "react-i18next";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";

import Tldr from "@/ui/Tldr";
import { SearchResponseItem } from "@/lib/SemanticScholarApi";
import SummaryTab from "@/components/article/SummaryTab";
import {
  MdCollectionsBookmark,
  MdOutlineCollectionsBookmark,
  MdOutlineFileDownload,
} from "react-icons/md";
import { useCollections } from "@/hooks/useCollections";
import AddElementToCollectionModal from "@/components/collections/AddElementToCollectionModal";
//@ts-expect-error citation-js does not have types
import Cite from "citation-js";

const Article = () => {
  const { article } = useLoaderData() as { article: SearchResponseItem };
  const navigate = useNavigate();
  const { collections } = useCollections();
  const { hash } = useLocation();
  const {
    isOpen: isModalOpen,
    onOpen: openModal,
    onClose: closeModal,
  } = useDisclosure();
  const { t } = useTranslation();
  const toast = useToast();
  console.log(article);
  /* const content = article.content
    ? article.content[language] || article.content?.en
    : null; */

  const tldr = article.tldr;
  const abstract = article.abstract;
  const link = article.externalIds.DOI
    ? `https://doi.org/${article.externalIds.DOI}`
    : article.openAccessPdf?.url || article.url;

  const copyReference = () => {
    const _id = article.citationStyles?.bibtex || article.externalIds.DOI;
    const cite = new Cite(_id);
    const reference = cite.format("bibliography", {
      template: "apa",
      format: "text",
    });
    window.navigator.clipboard
      .writeText(reference)
      .then(() => {
        toast({
          title: t("article.copied"),
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          title: t("article.notCopied", { reference }),
          status: "error",
          duration: 10000,
          isClosable: true,
        });
      });
  };

  return (
    <Container hasSearch>
      <Box
        width={"100%"}
        h={"100%"}
        borderRadius={24}
        bgColor={"#F2F6F9"}
        mt={8}
        p={[4, 8]}
        overflow={"hidden"}
        display={"flex"}
        flexDirection={"column"}
        gap={8}
      >
        <Button
          variant={"primary"}
          w={"fit-content"}
          onClick={() => navigate(-1)}
        >
          <Box
            borderWidth={1}
            borderColor={"#FFF"}
            borderRadius={100}
            w={6}
            h={6}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            mr={2}
          >
            <ArrowBackIcon />
          </Box>
          {t("article.back")}
        </Button>
        <Box
          p={[4, 8]}
          display={"flex"}
          flexDirection={"column"}
          gap={8}
          borderRadius={24}
          bgColor={"#fff"}
        >
          <Heading as={"h1"}>{article.title}</Heading>
          <Stack>
            <HStack wrap={"wrap"} mb={4}>
              <Button as={"a"} href={link} target="_blank" w={"fit-content"}>
                {t("article.source")}
                <LinkIcon ml={2} />
              </Button>
              {article.openAccessPdf?.url && (
                <Button
                  as={"a"}
                  href={article.openAccessPdf.url}
                  target="_blank"
                  w={"fit-content"}
                >
                  {t("article.pdf")}
                  <Icon as={MdOutlineFileDownload} ml={2} />
                </Button>
              )}
              <Button onClick={copyReference}>
                {t("article.copyReference")}
              </Button>
              <Button
                onClick={() => {
                  try {
                    navigator.canShare()
                      ? navigator.share({
                          url: window.location.href,
                        })
                      : navigator.clipboard
                          .writeText(window.location.href)
                          .then(() => {
                            toast({
                              title: t("article.urlCopied"),
                              status: "success",
                              duration: 5000,
                              isClosable: true,
                            });
                          })
                          .catch(() => {
                            toast({
                              title: t("article.urlNotCopied"),
                              status: "error",
                              duration: 10000,
                              isClosable: true,
                            });
                          });
                  } catch (error) {
                    navigator.clipboard
                      .writeText(window.location.href)
                      .then(() => {
                        toast({
                          title: t("article.urlCopied"),
                          status: "success",
                          duration: 5000,
                          isClosable: true,
                        });
                      })
                      .catch(() => {
                        toast({
                          title: t("article.urlNotCopied"),
                          status: "error",
                          duration: 10000,
                          isClosable: true,
                        });
                      });
                  }
                }}
              >
                {t("article.share")}
              </Button>
              <Button onClick={openModal}>
                <Icon
                  as={
                    collections.find((c) =>
                      c.papers.includes(article.corpusId.toString())
                    )
                      ? MdCollectionsBookmark
                      : MdOutlineCollectionsBookmark
                  }
                  w={6}
                  h={6}
                  mr={2}
                />

                {t("article.save")}
              </Button>
            </HStack>

            <Text>
              {article.publicationDate
                ? new Date(article.publicationDate).toLocaleDateString()
                : article.year}{" "}
              - {article.journal?.name} - {article.externalIds.DOI} -{" "}
              {article.citationCount || 0} citations
            </Text>
            <Text fontStyle={"italic"}>
              {article.authors.map((author) => author.name).join(", ")} et al.
            </Text>
          </Stack>
          <Tabs
            defaultIndex={hash === "#summary" ? 1 : 0}
            isFitted
            variant={"unstyled"}
          >
            <TabList
              borderBottomWidth={"2px"}
              borderBottomColor={"brand.100"}
              borderStyle={"solid"}
            >
              <Tab
                _selected={{
                  bg: "brand.100",
                }}
              >
                {t("article.generalInfo")}
              </Tab>
              <Tab
                _selected={{
                  bg: "brand.100",
                }}
              >
                {t("article.summary.tabs")}
              </Tab>
            </TabList>
            <TabIndicator
              mt={"-2px"}
              height="2px"
              bg="brand.500"
              borderRadius="1px"
            />
            <TabPanels mt={4}>
              <TabPanel>
                {tldr && (
                  <Text>
                    <Tldr />
                    {tldr.text}
                  </Text>
                )}

                {abstract && (
                  <Box>
                    <Heading size={"md"} as={"h2"} mt={4} mb={2}>
                      {t("article.abstract")}
                    </Heading>
                    <Text>{abstract}</Text>
                  </Box>
                )}
              </TabPanel>
              <TabPanel>
                <SummaryTab link={link} corpusId={article.corpusId} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Box>
      <AddElementToCollectionModal
        isOpen={isModalOpen}
        onClose={closeModal}
        elementId={article.corpusId.toString()}
        elementType="paper"
      />
    </Container>
  );
};

export default Article;
