import { useUserSession } from "@/hooks/useUserSession";
import AuthContainer from "@/ui/Auth/AuthContainer";
import { Heading, Text } from "@chakra-ui/react";
import { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";

const CompleteProfile = () => {
  const { t } = useTranslation();

  const { user } = useUserSession();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }

    if (user?.registrationCompleted) {
      navigate("/", {
        replace: true,
      });
    }
  }, [user, navigate]);

  return (
    <>
      {" "}
      <AuthContainer>
        <Heading mb={8} color={"#fff"}>
          {t("auth.register.subtitle.base")}
        </Heading>

        <Text fontWeight={"bold"}>{t("auth.old.subtitle.base")}</Text>
        <Outlet />
      </AuthContainer>
    </>
  );
};

export default CompleteProfile;
