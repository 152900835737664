import { create } from "zustand";

type SearchFiltersData = {
  year: {
    min: string;
    max: string;
  } | null;
  minCitations: number | undefined;
  influentialCitationFilter: boolean;
  isOpenAccess: boolean;
  fieldsOfStudy: string[];
  publicationTypes: string[];
};

type SearchFiltersActions = {
  setYear: (min: string, max: string) => void;
  setMinCitations: (minCitations: number) => void;
  setInfluentialCitationFilter: (influentialCitationFilter: boolean) => void;
  setIsOpenAccess: (isOpenAccess: boolean) => void;
  setFieldsOfStudy: (fieldsOfStudy: string[]) => void;
  setPublicationTypes: (publicationTypes: string[]) => void;
  reset: () => void;
};

const initialState: SearchFiltersData = {
  year: null,
  minCitations: 2,
  influentialCitationFilter: false,
  isOpenAccess: false,
  fieldsOfStudy: [],
  publicationTypes: [],
};

const useSearchFilters = create<SearchFiltersData & SearchFiltersActions>(
  (set) => ({
    ...initialState,
    setYear: (min, max) => set({ year: { min, max } }),
    setMinCitations: (minCitations) => set({ minCitations }),
    setInfluentialCitationFilter: (influentialCitationFilter) =>
      set({ influentialCitationFilter }),
    setIsOpenAccess: (isOpenAccess) => set({ isOpenAccess }),
    setFieldsOfStudy: (fieldsOfStudy) => set({ fieldsOfStudy }),
    setPublicationTypes: (publicationTypes) => set({ publicationTypes }),
    reset: () => set(initialState),
  })
);

export default useSearchFilters;
