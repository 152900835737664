import { RefObject } from "react";
import { useUserSession } from "./useUserSession";
import useSearchFilters from "./useSearchFilters";
import { increment, updateDoc } from "firebase/firestore";
import { useSubmit } from "react-router-dom";
import useArchieData from "./useArchieData";
import { usePaperCoinsDisclaimer } from "./usePaperCoinsDisclaimer";

const useSearchSubmit = (formRef: RefObject<HTMLFormElement>) => {
  const { user } = useUserSession();
  const { openDisclaimer } = usePaperCoinsDisclaimer();
  const {
    fieldsOfStudy,
    year,
    influentialCitationFilter,
    minCitations,
    isOpenAccess,
    publicationTypes,
  } = useSearchFilters();
  const displayed = useArchieData((state) => state.displayed);
  const submit = useSubmit();

  const handleSubmit = (isAiRedirect?: boolean) => {
    const values = new FormData(formRef.current as HTMLFormElement);
    const usedValues: [string, string][] = [];

    if (user && user.currentPlan !== "premium") {
      let price = 0;
      if (displayed.aiSearch) {
        price += 2;
      }
      if (displayed.insights) {
        price += 2;
      }
      if (displayed.summary) {
        price += 4;
      }

      if (price > user.paperCoins) {
        openDisclaimer("search");
        return;
      }
    }

    if (!values.get("q")) {
      return;
    }

    values.forEach((value, key) => {
      if (value && !["ai-search", "insights", "summary"].includes(key)) {
        usedValues.push([key, value.toString()]);
      }
    });

    if (displayed.aiSearch) {
      usedValues.push(["ai-search", "true"]);
    }

    if (displayed.insights) {
      usedValues.push(["insights", "true"]);
    }

    if (displayed.summary) {
      usedValues.push(["summary", "true"]);
    }

    if (isAiRedirect) {
      usedValues.push(["ai-search", "true"]);
    }

    if (fieldsOfStudy && fieldsOfStudy.length > 0) {
      usedValues.push(["fieldsOfStudy", fieldsOfStudy.join(",")]);
    }

    if (publicationTypes && publicationTypes.length > 0) {
      usedValues.push(["publicationTypes", publicationTypes.join(",")]);
    }

    if (year) {
      usedValues.push(["min_year", year.min]);
      usedValues.push(["max_year", year.max]);
    }
    console.log(minCitations);
    if (minCitations) {
      usedValues.push(["minCitations", minCitations.toString()]);
    }

    if (influentialCitationFilter) {
      usedValues.push(["influential", influentialCitationFilter.toString()]);
    }

    if (isOpenAccess) {
      usedValues.push(["isOpenAccess", isOpenAccess.toString()]);
    }

    if (user) {
      updateDoc(user.ref, {
        searchesCount: increment(1),
      });
    }

    const searchParams = new URLSearchParams([...usedValues]);

    submit(searchParams, {
      action: "/search",
      method: "get",
    });
  };

  return handleSubmit;
};

export default useSearchSubmit;
