import { Button, Box, Progress } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";
import {
  QueryConstraint,
  collection,
  getDocs,
  query,
  updateDoc,
} from "firebase/firestore";
import { dbIa } from "@/lib/firebase";
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from "@choc-ui/chakra-autocomplete";
import { useUserSession } from "@/hooks/useUserSession";

type Item = {
  label: string;
  value: string;
};

type FormData = {
  domain: string;
};

const Domain = () => {
  const { t, i18n } = useTranslation();
  const { user } = useUserSession();
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
  } = useForm<FormData>({
    defaultValues: {
      domain: user?.domain || "",
    },
  });
  const navigate = useNavigate();
  const onSubmit = handleSubmit(async (data) => {
    if (!user) return;

    await updateDoc(user.ref, {
      domain: data.domain,
    });

    if (data.domain === "medecine") {
      navigate("/complete-profile/specialty");
    } else {
      navigate("/complete-profile/identity");
    }
  });
  const value = watch("domain");
  const [domains, setDomains] = useState<Item[]>([]);

  useEffect(() => {
    (async () => {
      const constraints: QueryConstraint[] = [];

      const { docs } = await getDocs(
        query(collection(dbIa, "users_domains"), ...constraints)
      );

      const _domains = docs.map((doc) => ({
        label: doc.data()[i18n.language],
        value: doc.id,
      }));
      setDomains(_domains);
    })();
  }, [i18n.language]);

  return (
    <Box
      as="form"
      display={"flex"}
      flexDir={"column"}
      gap={8}
      w={"clamp(280px, 70%, 500px)"}
      onSubmit={onSubmit}
    >
      <Progress value={40} />
      {errors.root && <Box color={"red"}>{errors.root.message}</Box>}
      <AutoComplete
        openOnFocus
        value={domains.find((item) => item.value === value)?.label}
        onSelectOption={({ item }: { item: Item }) => {
          setValue("domain", item.value);
        }}
      >
        <AutoCompleteInput
          variant={"auth"}
          placeholder={t("auth.register.domain")}
          required
          {...register("domain")}
        />
        <AutoCompleteList>
          {domains.map((status) => (
            <AutoCompleteItem
              color="#000"
              key={status.value}
              value={status.value}
              label={status.label}
            >
              {status.label}
            </AutoCompleteItem>
          ))}
        </AutoCompleteList>
      </AutoComplete>
      <Button type="submit" variant={"primary"} isLoading={isSubmitting}>
        {t("auth.register.continue")}
      </Button>
    </Box>
  );
};

export default Domain;
