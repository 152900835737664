import { useUserSession } from "@/hooks/useUserSession";
import { functions } from "@/lib/firebase";
import initializeStripe from "@/lib/initializeStripe";
import Container from "@/ui/Container";
import PlanCard from "@/ui/PlanCard";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  ListItem,
  OrderedList,
  Switch,
  Tag,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { httpsCallable } from "firebase/functions";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Pricing = () => {
  const { t } = useTranslation();
  const { user } = useUserSession();

  const [mode, setMode] = useState<"monthly" | "yearly">("yearly");

  const openStripeSession = async () => {
    if (!user) return;
    const createCustomerSession = httpsCallable<
      {
        customerId: string;
        baseUrl: string;
      },
      string
    >(functions, "stripe-createCustomerSession");
    const { data } = await createCustomerSession({
      customerId: user.stripeCustomerId as string,
      baseUrl: window.location.origin,
    });

    window.location.href = data;
  };

  const requestCheckout = httpsCallable<
    { price: string; baseUrl: string },
    string
  >(functions, "stripe-createStripeSubscriptionSession");
  const handleSilverClick = async () => {
    const stripe = await initializeStripe();
    if (!stripe) return;

    const { data } = await requestCheckout({
      price:
        mode === "monthly"
          ? "price_1PQCO8CsQCsoHW1dfn6pyPMy"
          : "price_1PQCO8CsQCsoHW1dlVThDLvZ",
      baseUrl: window.location.origin,
    });

    stripe.redirectToCheckout({ sessionId: data });
  };

  const handlePremiumClick = async () => {
    const stripe = await initializeStripe();
    if (!stripe) return;

    const { data } = await requestCheckout({
      price:
        mode === "monthly"
          ? "price_1Q2aWgCsQCsoHW1dtnwdS8Sz"
          : "price_1Q2abxCsQCsoHW1d1gV54tXN",
      baseUrl: window.location.origin,
    });

    stripe.redirectToCheckout({ sessionId: data });
  };

  return (
    <Container hasSearch>
      <Box
        mt={8}
        pt={[4, 8]}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        gap={4}
        w={"100%"}
        h={"100%"}
        borderRadius={24}
        bgColor={"#F2F6F9"}
      >
        <Heading>{t("plans.subs.title")}</Heading>
        <Text textAlign={"center"} fontStyle={"italic"} fontSize={"xl"}>
          {t("plans.subs.subtitle")}
        </Text>
        <FormControl
          alignSelf={"center"}
          display="flex"
          alignItems="center"
          justifyContent={"center"}
          gap={2}
          mb={4}
        >
          <FormLabel htmlFor="mode-switch" mb="0" mr={0}>
            {t("plans.subs.mode.monthly")}
          </FormLabel>
          <Switch
            id="mode-switch"
            colorScheme="brand"
            isChecked={mode == "yearly"}
            onChange={() => setMode(mode === "monthly" ? "yearly" : "monthly")}
          />
          <FormLabel htmlFor="mode-switch" mb="0">
            {t("plans.subs.mode.yearly")}
          </FormLabel>
        </FormControl>
        <Box
          w={"100%"}
          display={"flex"}
          flexDir={["column", "column", "row"]}
          justifyContent={"flex-start"}
          overflow={"scroll"}
          sx={{
            "& > *": {
              width: ["100%"],
              minW: ["100%", "100%", 300],
              borderRadius: [16, 16, "none"],
            },
            "& > *:last-child": {
              borderRightRadius: 16,
              marginRight: "auto",
            },
            "& > *:first-child": {
              borderLeftRadius: 16,
              marginLeft: "auto",
            },
            "& > *:nth-child(3)": {
              borderRightRadius: 16,
              borderLeftRadius: 16,
            },
          }}
          px={4}
        >
          <PlanCard borderRightWidth={0} borderLeftRadius={16} my={4}>
            <Heading mb={4}>{t("plans.free.title")}</Heading>
            <Text fontStyle={"italic"} mb={4}>
              {t("plans.free.description")}
            </Text>
            <Text fontWeight={"bold"} fontSize={"2xl"} mb={4}>
              {t("plans.free.price")}
            </Text>
            <Button
              isDisabled={!user?.currentPlan}
              colorScheme="brand"
              onClick={openStripeSession}
              w={"100%"}
              mb={10}
            >
              {t(
                user?.currentPlan
                  ? "plans.button.downgrade"
                  : "plans.button.current"
              )}
            </Button>
            <UnorderedList>
              <ListItem>{t("plans.free.features.1")}</ListItem>
              <ListItem>{t("plans.free.features.2")}</ListItem>
              <ListItem>{t("plans.free.features.3")}</ListItem>
              <ListItem>
                {t("plans.free.features.4.title")}
                <UnorderedList>
                  <ListItem>{t("plans.free.features.4.3")}</ListItem>

                  <ListItem>{t("plans.free.features.4.1")}</ListItem>
                  <ListItem>{t("plans.free.features.4.2")}</ListItem>
                </UnorderedList>
              </ListItem>
              <ListItem>{t("plans.free.features.5")}</ListItem>
            </UnorderedList>
          </PlanCard>
          <PlanCard my={4}>
            <Heading mb={4}>{t("plans.silver.title")}</Heading>
            <Text fontStyle={"italic"} mb={4}>
              {t("plans.silver.description")}
            </Text>
            <Text fontWeight={"bold"} fontSize={"2xl"} mb={4}>
              {mode === "monthly"
                ? t("plans.silver.price.monthly")
                : t("plans.silver.price.yearly")}
            </Text>
            <Button
              isDisabled={user?.currentPlan === "silver"}
              colorScheme="brand"
              w={"100%"}
              onClick={handleSilverClick}
              mb={10}
            >
              {t(
                user?.currentPlan === "silver"
                  ? "plans.button.current"
                  : "plans.button.choose"
              )}
            </Button>
            <Text mb={4}>{t("plans.silver.features.1")}</Text>
            <UnorderedList>
              <ListItem>
                {t("plans.silver.features.4.title")}
                <UnorderedList>
                  <ListItem>{t("plans.free.features.4.3")}</ListItem>

                  <ListItem>{t("plans.free.features.4.1")}</ListItem>
                  <ListItem>{t("plans.free.features.4.2")}</ListItem>
                </UnorderedList>
              </ListItem>
            </UnorderedList>
          </PlanCard>
          <PlanCard
            borderRadius={16}
            borderWidth={2}
            borderColor={"brand.500"}
            position={"relative"}
          >
            <Tag
              position={"absolute"}
              top={0}
              right={0}
              borderRadius={0}
              borderTopRightRadius={12}
              p={2}
              color={"white"}
              bgColor={"brand.500"}
            >
              {t("plans.popular")}
            </Tag>
            <Heading mb={4}>{t("plans.premium.title")}</Heading>
            <Text fontStyle={"italic"} mb={8}>
              {t("plans.premium.description")}
            </Text>
            <Text fontWeight={"bold"} fontSize={"2xl"} mb={4}>
              {mode === "monthly"
                ? t("plans.premium.price.monthly")
                : t("plans.premium.price.yearly")}
            </Text>
            <Button
              onClick={handlePremiumClick}
              isDisabled={user?.currentPlan === "premium"}
              colorScheme="brand"
              w={"100%"}
              mb={10}
            >
              {t(
                user?.currentPlan === "premium"
                  ? "plans.button.current"
                  : "plans.button.choose"
              )}
            </Button>
            <Text mb={4}>{t("plans.premium.features.1")}</Text>
            <OrderedList>
              <ListItem>{t("plans.premium.features.4")}</ListItem>

              <ListItem>{t("plans.premium.features.2")}</ListItem>
              <ListItem>{t("plans.premium.features.3")}</ListItem>
            </OrderedList>
          </PlanCard>
          <PlanCard borderRightRadius={16} my={4}>
            <Heading mb={4}>{t("plans.enterprise.title")}</Heading>
            <Text fontStyle={"italic"} mb={8}>
              {t("plans.enterprise.description")}
            </Text>
            <Text fontWeight={"bold"} fontSize={"2xl"} mb={4}>
              {t("plans.enterprise.price")}
            </Text>
            <Button
              as={Link}
              to={"/contact"}
              colorScheme="brand"
              w={"100%"}
              mb={10}
            >
              {t("plans.button.contact")}
            </Button>
            <Text mb={4}>{t("plans.enterprise.features.1")}</Text>
            <UnorderedList>
              <ListItem>{t("plans.enterprise.features.2")}</ListItem>
              <ListItem>{t("plans.enterprise.features.3")}</ListItem>
              <ListItem>{t("plans.enterprise.features.4")}</ListItem>
              <ListItem>{t("plans.enterprise.features.5")}</ListItem>
              <ListItem>{t("plans.enterprise.features.6")}</ListItem>
              <ListItem>{t("plans.enterprise.features.7")}</ListItem>
            </UnorderedList>
          </PlanCard>
        </Box>
        <PlanCard
          borderRadius={16}
          borderWidth={2}
          alignSelf={"center"}
          mx={4}
          display={"flex"}
          flexDirection={"column"}
          gap={4}
          alignItems={"flex-start"}
        >
          <Heading>{t("plans.student.title")}</Heading>
          <Text>{t("plans.student.description")}</Text>
          <Text mb={-4}>{t("plans.student.list.title")}</Text>
          <UnorderedList>
            <ListItem>{t("plans.student.list.email")}</ListItem>
            <ListItem>{t("plans.student.list.certificate")}</ListItem>
          </UnorderedList>
          <Button
            as={Link}
            colorScheme="brand"
            to="mailto:contact@paperdoc-app.com"
          >
            {t("plans.student.button")}
          </Button>
        </PlanCard>
        <Text m={4} fontStyle={"italic"}>
          * {t("plans.paperCoinsDisclaimer")}
        </Text>
      </Box>
    </Container>
  );
};

export default Pricing;
