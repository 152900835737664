import { ArchieData } from "@/lib/types/ArchieData";
import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

type Actions = {
  setArchieData: (
    data: Partial<Omit<Omit<ArchieData, "currentQuery">, "refs">>
  ) => void;
  setCurrentQuery: (query: string) => void;
  setSelectedAnswers: (answers: {
    yes: boolean;
    no: boolean;
    potentially: boolean;
  }) => void;
  setRefs: (refs: State["refs"]) => void;
  toggleFilterByAnswer: () => void;

  reset: () => void;
};

type State = ArchieData;

const initialState: State = {
  archieYO: null,
  archieQ: null,
  archieSUMM: null,
  currentQuery: "",
  papers: [],
  language: "en",
  selectedAnswer: {
    yes: true,
    no: true,
    potentially: true,
  },
  filterByAnswer: false,
  cleanedQuery: "",

  displayed: {
    aiSearch: true,
    insights: true,
    summary: true,
  },
  refs: new Set<string>(),
  token: "",
  queryParams: "",
};

const useArchieData = create<State & Actions>()(
  persist(
    (set, get) => ({
      ...initialState,
      setArchieData: (data) => set(data),
      setCurrentQuery: (query) => set({ currentQuery: query }),
      setSelectedAnswers: (answers) => set({ selectedAnswer: answers }),
      setRefs: (refs) =>
        set((state) => {
          const newRefs = new Set(state.refs);
          refs.forEach((ref) => newRefs.add(ref));
          return { refs: newRefs };
        }),
      reset: () =>
        set({
          ...initialState,
          displayed: get().displayed,
        }),
      toggleFilterByAnswer: () =>
        set((state) => ({ filterByAnswer: !state.filterByAnswer })),
    }),
    {
      name: "archie-search",
      storage: createJSONStorage(() => sessionStorage, {
        reviver(key, value) {
          if (key === "refs" && value instanceof Array) {
            return new Set([...value]);
          }
          return value;
        },
        replacer(key, value) {
          if (value && key === "refs" && value instanceof Set) {
            return [...value];
          }
          return value;
        },
      }),
    }
  )
);

export default useArchieData;
