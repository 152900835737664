import { functions } from "@/lib/firebase";
import { httpsCallable } from "firebase/functions";

type RequestKnownSummaryRequest = {
  id: string;
  corpus_id: string;
};

type RequestKnownSummaryResponse = {
  summary: string;
};

const requestKnownSummary = httpsCallable<
  RequestKnownSummaryRequest,
  RequestKnownSummaryResponse
>(functions, "request_known_summary");

export { requestKnownSummary };
