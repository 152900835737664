import { functions } from "../firebase";
import { httpsCallable } from "firebase/functions";

import { AiSearchResponse } from "../archie";

type AiSearchRequest = {
  query: string;
  year_range?: [number, number];
  selected_fields_of_study?: Array<string>;
  is_open_access?: boolean;
  influential_citation_filter?: boolean;
  selected_publication_types?: Array<string>;
  min_citations?: number;
};

const aiSearch = httpsCallable<AiSearchRequest, AiSearchResponse>(
  functions,
  "ai_search"
);

export { aiSearch };
